import PageTitle from '../../components/pages/pageTitle'
import AboutUs from '../../components/pages/about-us'
import Randevu from '../../components/pages/randevu'
const About = () => {
    return (
        <div>
            <PageTitle title='about' value='Haqqımızda'/>
            <AboutUs />
            <Randevu />
        </div>
    )
}

export default About
