import './index.scss'
import Logo from '../../../assets/images/logo.svg'
import {Link, NavLink, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import Sidebar from '../../pages/sidebar'
import {FiMenu} from 'react-icons/fi'
import classNames from 'classnames'

const Navbar = () => {
    const menuItem = (title, path) => {
        return {path, title}
    }
    const location = useLocation()
    const hash = location.hash
    useEffect(() => {
        if (hash.includes('#')) {
            const component = document.querySelector(hash)
            component.scrollIntoView(true)
        } else {
            window.scroll(0, 0)
        }
    }, [location])
    const [open, setOpen] = useState(false)
    const openClose = () => {
        setOpen(!open)
    }
    const menu = [
        menuItem('Haqqımızda', '/info'),
        menuItem('Xidmətlərimiz', '/#services'),
        menuItem('Həkimlər', '/doctors'),
        menuItem('Əlaqə', '/contact')
    ]
    return (
        <nav className="nav">
            <Link to={'/'} className="nav-logo">
                <img src={Logo} alt="32 Gözəl"/>
            </Link>
            <ul className="comp-title">
                {menu.map(item => (
                    !item?.path?.includes('#') ? <li key={item.path}>
                        <NavLink to={item.path}>{item.title}</NavLink>
                    </li> : (
                        <li key={item.path}>
                            <a href={item.path}
                                className={classNames({
                                    'active': `/${location.hash}` === item.path
                                })}
                            >{item.title}</a>
                        </li>
                    )
                ))}
            </ul>
            <a className="button" href="/#online-randevu">
                Onlayn randevu
            </a>
            <div className="menu-logo">
                <FiMenu onClick={openClose} className='icon'/>
            </div>
            <div className={open ? 'menu-cards active' : 'menu-cards'}>
                <Sidebar menu={menu} openClose={openClose} location={location}/>
            </div>
        </nav>
    )
}

export default Navbar
