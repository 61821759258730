import './index.scss'
import Logo from '../../../assets/images/logo.svg'
import Tiktok from '../../../assets/images/tiktok.svg'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import axios from 'axios'
import StepLogo from '../../../assets/images/steplogo2.svg'

const Footer = () => {
    const linkElement = (title, path, subTitle, icon) => {
        return {title, path, icon, subTitle}
    }

    const [about, setAbout] = useState({})
    const aboutData = async() => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/about`)
        setAbout(data?.data?.data)
    }
    useEffect(() => {
        aboutData()
    }, [])
    const links = [
        linkElement('Haqqımızda', '/info'),
        linkElement('Xidmətlərimiz', '/#services'),
        linkElement('Həkimlərimiz', '/doctors'),
        linkElement('Onlayn randevu', '/#online-randevu'),
        linkElement('Əlaqə', '/contact')
    ]

    const socials = [
        linkElement('facebook', about?.facebook, null, <i className="pi pi-facebook" style={{color: '#0163C1'}}/>),
        linkElement('tiktok', about?.tiktok, null, <img src={Tiktok}
            alt="ssalam" style={{color: '#0163C1'}}/>),
        linkElement('instagram', about?.instagram, null, <i className="pi pi-instagram" style={{color: '#0163C1'}}/>)
    ]

    return (
        <footer>
            <div className="main-footer">
                <Link to="/" className="logo">
                    <img src={Logo} alt="32 gözəl"/>
                </Link>
                <div className="links-container">
                    <h3>Faydalı linklər</h3>
                    <ul className="links">
                        {links.map(item => (
                            <li key={item.path}>
                                <a href={item.path}>{item.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="links-container">
                    <h3>Əlaqə məlumatları</h3>
                    <ul className="links contact">
                        <li key={`tel:${about?.phone}`}>
                            <i className="pi pi-phone" style={{color: 'rgb(1, 99, 193)'}}/>
                            <span>
                                <h3>Telefon</h3>
                                {
                                    about?.phone?.split(',').map(elem =>
                                        <a href={`tel:${elem}`} key={elem}>{elem}</a>
                                    )
                                }
                            </span>
                        </li>
                        <li key='#'>
                            <i className="pi pi-map-marker" style={{color: 'rgb(1, 99, 193)'}}/>
                            <span>
                                <h3>Address</h3>
                                <a href='#address'>{about?.address}</a>

                            </span>
                        </li>
                        <li key={`mailto:${about?.email}`}>
                            <i className="pi pi-envelope"
                                style={{color: 'rgb(1, 99, 193)'}}/>
                            <span>
                                <h3>Email</h3>
                                <a href={`mailto:${about?.email}`}>{about?.email}</a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="links-container">
                    <h3>İş saatları</h3>
                    <div className="flex align-items-start gap-4">
                        <i className="pi pi-clock" style={{color: 'rgb(1, 99, 193)', fontSize:'24px'}}/>
                        <ul className='links contact'>
                            <li key='hergun'>
                                <span>
                                    <h3>Hər gün:</h3>
                                    <span>09:00 - 20:00</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="links-container">
                    <h3>Sosial şəbəkələr</h3>
                    <ul className="links socials">
                        {socials.map(item => (
                            <li key={item.title}>
                                <a target="_blank" href={item.path}>{item.icon}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="secondary-footer">
                <span>
                    ©{new Date().getFullYear()} - 32İnci | Bütün hüquqlar qorunur
                </span>
                <Link className="step-logo" target='_blank' to='https://threesteps.az'><img src={StepLogo}
                    alt=""/></Link>
            </div>
        </footer>
    )
}

export default Footer
