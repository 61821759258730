import DoctorsPhoto from '../../../assets/images/clinica.jpg'
import './about.scss'
const AboutUs = () => {
    return (
        <div className='about-us'>
            <div className="left">
                <div className="about-title">Haqqımızda</div>
                <div className="about-desc">
                    Sizə ən yüksək keyfiyyətli stomatoloji xidmət göstərməyə can atdığımız 32 İnci-yə xoş gəlmisiniz. Təcrübəli stomatoloqlar komandamız bütün xəstələrimiz
                    üçün rahat və qonaqpərvər bir mühit yaratmağa sadiqdir.<br/>
                    32 İncidə biz rutin müayinələr, təmizləmələr, plomblar, kök kanalları, çəkilişlər, dişlərin ağardılması, ortodontik müalicələr və diş əti xəstəliklərinin müalicəsi daxil olmaqla geniş çeşiddə stomatoloji
                    xidmətlər təklif edirik. Mümkün olan ən yaxşı qayğını almanızı təmin etmək üçün ən müasir texnologiya və texnikalardan istifadə edirik.<br/>
                    32 İnci-dəki missiyamız sizə sağlam, gözəl təbəssüm əldə etməyə və qorumağa kömək etməkdir. Biz diş sağlamlığının ümumi sağlamlıq və rifah üçün vacib olduğuna inanırıq və xüsusi ehtiyaclarınıza cavab verən fərdi müalicə planı hazırlamaq üçün sizinlə işləyirik.
                </div>
            </div>
            <div className="right">
                <img src={DoctorsPhoto} alt=""/>
            </div>
        </div>
    )
}

export default AboutUs
