import {Outlet} from 'react-router-dom'
import './index.scss'
import Navbar from '../navbar'
import Footer from '../footer'

const MainLayout = () => {
    return (
        <div className="container">
            <Navbar/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default MainLayout
