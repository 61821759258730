import './education.scss'
import RandevuForm from '../randevuForm'
import EduWork from '../eduWork'
const Education = ({education}) => {
    return (
        <div className='education'>
            <EduWork edu={true} education={education}/>
            <RandevuForm edu={true}/>
        </div>
    )
}

export default Education
