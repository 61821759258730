import PageTitle from '../../components/pages/pageTitle'
import DoctorsList from '../../components/pages/doctorsList'
import Randevu from '../../components/pages/randevu'
const Doctors = () => {
    return (
        <div>
            <PageTitle title='doctors' />
            <DoctorsList />
            <Randevu />
        </div>
    )
}

export default Doctors
