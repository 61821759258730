import React from 'react'
import './index.scss'

const Address = ({height}) => {
    return (
        <div className='address' id='address'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6071.745444319223!2d49.74243542039143!3d40.45595393650906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403085f1477b93a5%3A0xf354a5a58a9b134a!2zMzIgxLBuY2kgRGnFnyBLbGluaWthc8Sx!5e0!3m2!1str!2saz!4v1680258506363!5m2!1str!2saz"
                height={height}
                id="address"
                width="100%"
                allowFullScreen={true}
                style={{border: 'none'}}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className='iframe'/>
        </div>
    )
}

export default Address
