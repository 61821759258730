import './index.scss'

import 'react-toastify/dist/ReactToastify.css'
import RandevuForm from '../randevuForm'
import Address from '../address'
const Randevu = () => {
    return (
        <div className="online-randevu" id="online-randevu">
            <div className="content">
                <div className="left">
                    <div className="top">
                        <p>Daha sağlam təbəssüm üçün yerinizi ayırın.</p>
                    </div>
                    <RandevuForm />
                </div>
                <Address height='780px'/>
            </div>
        </div>
    )
}

export default Randevu
